<!-- eslint-disable array-callback-return -->
<script setup>
import docToBlob from '~/utils/kmob/docBlob'

const props = defineProps({
  photo: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: 'Title',
  },
  exludes: {
    type: Array,
    default: () => [],
  },
})

const Loading = ref(false)

const docBlob = ref(null)

watchEffect(async () => {
  if (props.photo.length > 0) {
    Loading.value = true
    try {
      const blob = await docToBlob(props.photo, props.exludes)
      docBlob.value = blob
    }
    catch (error) {
      console.info('info:', error)
    }
    finally {
      Loading.value = false
    }
  }
})
</script>

<template>
  <ClientOnly>
    <div>
      <KmobCard :title="props.title" :border-bottom="false" class="mx-8">
        <div v-if="props.photo.length > 0" class="grid grid-cols-2 grid-rows-1 gap-4">
          <template v-for="(item, index) in (docBlob ? docBlob : 4)" :key="index">
            <div class="mb-[24px] sm:w-1/2 md:w-[273px] xl:w-[343px]">
              <!-- Tampilkan BaseUploaderPreview jika docBlob ada -->
              <template v-if="docBlob && !Loading">
                <BaseUploaderPreview
                  :populate-file="item ? item : ''"
                  placeholder="Doc"
                  helper-text="Preview Document"
                />
              </template>
              <!-- Tampilkan pesan "Document Loading...." jika docBlob tidak ada -->
              <template v-if="Loading">
                <KSkeleton
                  height="65.0955px"
                  width="343px"
                  repeat="1"
                />
              </template>
              <template v-if="!docBlob && !Loading">
                <div class="text-content mb-[24px]">
                  -
                </div>
              </template>
            </div>
          </template>
        </div>
      </KmobCard>
    </div>
  </ClientOnly>
</template>
