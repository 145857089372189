export default async function docToBlob(params, exclude = []) {
  if (!Array.isArray(params) || params.length === 0)
    return

  try {
    const modifiedDocument = await Promise.all(
      params
        .filter(item => !exclude.includes(item.type))
        .map(async (item) => {
          const blobUrl = await useGetBlobUrl(item.url)
          return {
            ...item,
            name: item.type,
            url: blobUrl,
            file_type: 'jpeg',
          }
        }),
    )

    return modifiedDocument
  }
  catch (error) {
    console.error('Error processing documents:', error)
    throw error
  }
}
